// import * as firebase from 'firebase';
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/functions';

//! Business Logic Company 1 - Bright Star plastic =====================
const firebaseConfig = {
    apiKey: 'AIzaSyCJasW8GbJxzW3rfyP_Yk4__naSdifg9UM',
    authDomain: 'busineogicprod.firebaseapp.com',
    databaseURL: 'https://busineogicprod.firebaseio.com',
    projectId: 'busineogicprod',
    storageBucket: 'busineogicprod.appspot.com',
    messagingSenderId: '638287564138',
    appId: '1:638287564138:web:e6a03d07aa60da6520a913',
    measurementId: 'G-VMKZQJLC9Q',
};

const htmlTitle = 'BRIGHT STAR PLASTICS INC';

const publicKey =
    'pk_live_51GxCvtDyd6CdcpMElruIo0LAUvdDC2ZXghrSLK7CYaU3sGYOahsxDDcmRwCQLe66ANpeneicCacci11aLiPl0Psk00ss4S46S6';

//! change .firebaserc file to point to correct project id -> busineogicprod
//! ===================================================================

//
//
//! Demo Account ===============================================================================
// const firebaseConfig = {
//     apiKey: 'AIzaSyBs2BY0MvUYe8JIWgXrF4FAAXuYE-AI1dY',
//     authDomain: 'busineogicdemo.firebaseapp.com',
//     databaseURL: 'https://busineogicdemo.firebaseio.com',
//     projectId: 'busineogicdemo',
//     storageBucket: 'busineogicdemo.appspot.com',
//     messagingSenderId: '573574900827',
//     appId: '1:573574900827:web:823792ed57dd6b4530237e',
//     measurementId: 'G-1HMYX9KFDF',
// };
// const htmlTitle = 'Demo Account';

// const publicKey =
//     'pk_live_51IK1DTGe9u9gINqWpYVqCXfjdtu95tjoftkch2IwPjAff1nqibe0WFr8wRhFH6IcOByJOGeOEZvthTjhySRSkQSt00jRkJke2x';

//! Business Logic Company 2 =================================================================================

// const firebaseConfig = {
//     apiKey: 'AIzaSyBZ6_e4Oe7_t8yaUnvy1k5cc7MDnE64I8U',
//     authDomain: 'busineogic2.firebaseapp.com',
//     projectId: 'busineogic2',
//     storageBucket: 'busineogic2.appspot.com',
//     messagingSenderId: '655573356056',
//     appId: '1:655573356056:web:75442d72879d88c1d0fd6d',
//     measurementId: 'G-XFFYC668PL',
// };

// const htmlTitle = 'Smart 2';

// const publicKey =
//     'pk_live_51IK1DTGe9u9gINqWpYVqCXfjdtu95tjoftkch2IwPjAff1nqibe0WFr8wRhFH6IcOByJOGeOEZvthTjhySRSkQSt00jRkJke2x';

//! change .firebaserc file to point to correct project id -> busineogic2
//! ===================================================================

firebase.initializeApp(firebaseConfig);
const db = firebase.firestore();
const auth = firebase.auth();
const functions = firebase.app().functions('asia-northeast1');
// const functions = firebase.app().functions('us-east1');

export { db, auth, functions, firebase, htmlTitle, publicKey };
